<template>
  <div class="">
    <YzgHeader></YzgHeader>
    <div class="instuctions-header">
      <div class="content">
        <div class="top-box">
          <span class="instuctions-title">为什么选择云掌柜</span>
          <p class="instuctions-desc">
            云掌柜作为住宿业数字化营销管家，提供专业化的民宿管理工具与多维生态资源整合营销服务。专注于服务好每一位掌柜，持续给用户提供长期、稳定的服务，让天下没有难做的民宿生意。
          </p>
        </div>
        <div class="bottom_box"></div>
      </div>
    </div>
    <div class="instuctions-wrapper">
      <div class="project-text">
        <p class="inn_project_title" style="margin-bottom: 0">这些民宿都选择云掌柜</p>
        <p class="inn_project_desc" style="margin-bottom: 0">创立{{createYear}}年，持续服务于{{userNumber}}w+民宿用户</p>
      </div>
      <div class="project_list_feature">
        <div class="project_list_feature_box">
          <div class="imgText">
            <div class="imgDiv">单体民宿</div>
            <img src="../assets/pic/banner01.png">
          </div>
          <div class="imgText">
            <div class="imgDiv">连锁品牌</div>
            <img src="../assets/pic/banner02.jpg">
          </div>
          <div class="imgText">
            <div class="imgDiv">民宿综合体</div>
            <img src="../assets/pic/banner03.jpg">
          </div>
          <div class="imgText">
            <div class="imgDiv">民宿集群</div>
            <img src="../assets/pic/banner04.png">
          </div>

        </div>
      </div>
      <div class="project-text">
        <p class="inn_project_title">如果你在运营一家民宿，那么你需要:</p>
        <p class="inn_project_title">专业化民宿管理工具</p>
        <p class="inn_project_desc">
          专业化民宿管理工具，提高管理效率降低成本
          <br class="media_br" />
          — 帮民宿管好店
        </p>
      </div>
      <div class="project_list_box">
        <ul class="grid_list">
          <li>
            <div class="icon_box">
              <i class="icon_roomStatus"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">房态管理</span>
              <div class="desc_box">
                多终端数据同步，云端存储
                <br />
                可电脑手机平板，同时登录
                <br />
                夜审脏房管理等，高效管理
              </div>
            </div>
          </li>
          <li>
            <div class="icon_box">
              <i class="icon_statistics"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">财务统计</span>
              <div class="desc_box">
                多维度查看民宿运营状况
                <br />
                深分析各项民宿经营数据
                <br />
                报表易操作支持筛选导出
              </div>
            </div>
          </li>
          <li>
            <div class="icon_box">
              <i class="icon_grid"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">扩展功能</span>
              <div class="desc_box">
                身份证驱动，快速读取客人入住信息
                <br />
                多门店管理，轻松实现民宿连锁管理
                <br />
                智能门锁，对接芝麻云、火河、云丁
                <br />
                权限管理，子账号分权限管理更放心
              </div>
            </div>
          </li>

          <li>
            <div class="icon_box">
              <i class="icon_marketing"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">商超管理</span>
              <div class="desc_box">
                商超餐饮便捷售卖，提高二销收入
                <br />
                多终端订单联通，支持关联至客房
                <br />
                轻松做营销，高效经营民宿多业态
              </div>
            </div>
          </li>
          <li>
            <div class="icon_box">
              <i class="icon_channel"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">渠道直连</span>
              <div class="desc_box">
                直连国内外20+在线预订平台
                <br />
<!--                是行业内直连渠道最多的PMS-->
<!--                <br />-->
                多渠道房态同步，可批量改价
                <br />
                全面提高售房渠道的管理效率
              </div>
            </div>
          </li>
          <li>
            <div class="icon_box">
              <i class="icon_more"></i>
            </div>
            <div class="f-c-c-c">
              <span class="label">COMING SOON</span>
              <div class="desc_box">
                <p class="need-more">你需要哪些新功能，快来告诉我们吧~</p>
                <img src="../assets/pic/yzg_qr_code.png"
                     alt=""
                     style="width: 80px; margin-top: 8px" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="project-text mt160">
        <p class="inn_project_title">如果你有下面这些问题，那么你需要:</p>
        <p class="inn_project_title">多维生态资源整合营销平台</p>
        <p class="inn_project_desc">
          多元化营销资源整合，增加预订渠道提升收入
          <br class="media_br" />
          — 帮民宿多卖房
        </p>
      </div>
      <div class="ota_module_box">
        <ul>
          <li class="">
            <div class="ota_module_box_item">
              <i class="ota_wexin"></i>
              <span class="ota_title_theme">OTA佣金太高，想要拥有自主预订平台？</span>
              <div class="ota_bottom">
                <p class="title">微信营销</p>
                <p class="info">
                  微官网：开发独家微官网，搭建独立自有的预订平台
                </p>
                <p class="info">
                  微信小程序：开发专属小程序，在微信生态内轻松售房
                </p>
                <p class="info">全都“0佣金”哦~</p>
              </div>
            </div>
          </li>
          <li class="">
            <div class="ota_module_box_item">
              <i class="ota_gift"></i>
              <span class="ota_title_theme">淡季订单太少，全年入住率不高？</span>
              <div class="ota_bottom">
                <p class="title">预售营销</p>
                <p class="info">策划民宿专属“住宿+”预售套餐</p>
                <p class="info">快速去库存，创建私域流量池</p>
                <p class="info">单店最高销售额100w+元</p>
              </div>
            </div>
          </li>
          <li class="">
            <div class="ota_module_box_item">
              <i class="ota_member"></i>
              <span class="ota_title_theme">积累了一些粉丝，但复购率太低？</span>
              <div class="ota_bottom">
                <p class="title">会员营销</p>
                <p class="info">设置会员等级，给予不同福利</p>
                <p class="info">累计会员积分，给予消费抵扣</p>
                <p class="info">利用智能短信，维护会员关系</p>
              </div>
            </div>
          </li>
          <li class="">
            <div class="ota_module_box_item">
              <i class="ota_computed"></i>
              <span class="ota_title_theme">OTA网站订单太少，网站转化率太低？</span>
              <div class="ota_bottom">
                <p class="title">网站营销</p>
                <p class="info">专业团队指导解决一系列OTA运营难题</p>
                <p class="info">上线网站挂牌挂冠，提升排名速增收益</p>
                <p class="info">可优化携程、美团、途家等平台</p>
              </div>
            </div>
          </li>
          <li class="">
            <div class="ota_module_box_item">
              <i class="ota_daixiao"></i>
              <span class="ota_title_theme">现有渠道订单太少，上线更多平台管理成本太高？</span>
              <div class="ota_bottom">
                <p class="title">房源分销</p>
                <p class="info">一键分销民宿库存</p>
                <p class="info">已覆盖多个订房平台</p>
                <p class="info">实现民宿订单长尾增长</p>
              </div>
            </div>
          </li>
          <!-- <li class="">
            <div class="ota_module_box_item">
              <i class="ota_phone"></i>
              <span class="ota_title_theme">了解更多服务，请咨询</span>
              <div class="ota_bottom">
                <p class="title">0512-83621081</p>
                <p class="info">周一至周五 9:00～18:00</p>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
      <div class="project-text mt160">
        <p class="inn_project_title">
          如果你想了解更多民宿相关的服务，那么你需要:
        </p>
        <p class="inn_project_title">云掌柜生态服务链</p>
        <p class="inn_project_desc">
          生态化民宿升级服务，赋能民宿建立竞争优势
          <br class="media_br" />
          — 帮民宿建生态
        </p>
      </div>
      <div class="yzg_ota_brand_box">
        <!-- <ul class="yzg_brand_list">
          <li>
            <div class="item_box">
              <i class="icon_logo_school"></i>
              <div>
                <span class="yzg_ota_logo_title">民宿学苑</span>
                <p class="yzg_ota_brand_desc">
                  拥有多年民宿行业深耕经验，通过举办线上直播课与线下交流会，打造民宿人共同学习交流的平台，让爱学习的民宿人更有钱赚。
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item_box">
              <i class="icon_logo_youxuan"></i>
              <div>
                <span class="yzg_ota_logo_title">​云优选​</span>
                <p class="yzg_ota_brand_desc">
                  为民宿提供各类高性价比的客房用品，包含易耗品、洗浴护润用品、布草等。
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item_box">
              <i class="icon_logo_xiaosu"></i>
              <div>
                <span class="yzg_ota_logo_title">​小宿Design​​</span>
                <p class="yzg_ota_brand_desc">
                  为民宿提供设计施工与软装升级服务，已帮助多家民宿解决装修设计难题，风格鲜明，经验丰富。
                  ​
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item_box">
              <i class="icon_logo_OpenKitchen​​"></i>
              <div>
                <span class="yzg_ota_logo_title">OpenKitchen​​</span>
                <p class="yzg_ota_brand_desc">
                  开设民宿餐饮培训课程，通过小班授课手把手教学，让学员迅速掌握高颜值餐品的制作，打造民宿经营多业态。
                  ​
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item_box">
              <i class="icon_logo_baoxian"></i>
              <div>
                <span class="yzg_ota_logo_title">民宿保险​</span>
                <p class="yzg_ota_brand_desc">
                  联合保险服务商，独家定制适合民宿经营的保险产品，让民宿经营更有保障更安心。
                  ​
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="item_box">
              <i class="icon_logo_liangku"></i>
              <div>
                <span class="yzg_ota_logo_title">​良库好货</span>
                <p class="yzg_ota_brand_desc">
                  云掌柜旗下旅游社交电商平台，精选全国优质民宿货源，进行线上预售，为民宿产生订单并积累私域流量。
                  ​
                </p>
              </div>
            </div>
          </li>
        </ul> -->
        <div class="yzg_feature_list">
          <div class="item">
            <div>
              <div class="f-r-s-c">
                <i class="yzg_feature_icon"></i>
                <div class="ml16">
                  <p class="yzg_feature_title">民宿学苑​</p>
                  <p class="yzg_feature_desc">民宿充电站</p>
                </div>
              </div>
              <div class="block14">
                <span class="txt14">线上课程</span>
                <div class="group6"></div>
                <span class="word22">线下沙龙</span>
                <div class="group7"></div>
                <span class="txt15">大咖私享会</span>
              </div>
              <span class="word23">
                ​
                专注于打造专属于民宿人“学习、分享、交流”的成长平台。&nbsp;线上课程与线下沙龙相结合的分享形式，定期推出民宿运营管理干货知识，&nbsp;全面提升民宿人的认知和盈利能力。让爱学习的民宿人更有钱赚！
              </span>
            </div>
          </div>
          <div class="item mt64">
            <div>
              <div class="f-r-s-c">
                <i class="yzg_feature_innschool"></i>
                <div class="ml16">
                  <p class="yzg_feature_title">民宿集群</p>
                  <p class="yzg_feature_desc">数字化管理服务方案</p>
                </div>
              </div>
              <div class="block17">
                <div class="main3"
                     v-for="(item,index) in innColony"
                     :key="index"
                     :class="currentNum == index ? 'mian2':'' "
                     @click="currentNum = index; currentText = item.text">
                  <span class="word26">{{item.title}}</span>
                </div>
              </div>
              <img class="icon4 mt16"
                   referrerpolicy="no-referrer"
                   src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng96e6eecfabdce8aa05c7a760e9e7a5759c7e6eea9026830b36fe46fd0ff2e27b" />
              <span class="word29">
                {{currentText}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <YzgFooter></YzgFooter>
  </div>
</template>

<script>

import YzgHeader from "@/components/YzgHeader";
import YzgFooter from "@/components/YzgFooter";
import axios from 'axios'

export default {
  name: "yzgInstructions",
  components: {
    YzgHeader,
    YzgFooter,
  },
  data () {
    return {
      innColony: [
        {
          title: '数字化管理服务方案',
          text: '云掌柜积极响应国家「助力乡村振兴」的号召，正式推出「民宿集群-数字化管理服务方案」。为入驻集群的民宿提供CRS中央预订系统，解决业主多渠道营销管理的难题，提升民宿管理整体数字信息化水平，助力乡村文旅振兴。'
        },
        { title: '目的地品牌营销', text: '整合全网媒体资源扩大品牌知名度。与多家电视媒体合作打造多档民宿类综艺，推广覆盖全网视频网站，运营C端媒体矩阵，向全网500万+流量池精准推荐。' },
        { title: '目的地流量运营', text: '打造集群私域流量池，利用技术转化订单。协助集群参与抖音、快手、小红书等UGC平台官方活动，享流量扶持，借助小程序预订平台、POI接口等技术一键转化订单。' },
        {
          title: '目的地大数据',
          text: '利用数据智慧集群经营。为集群开发电子大屏显示、智能数据看板等，实现数据的集中采集、分析、处理与协同应用。云掌柜连续多年发布的民宿行业数据报告已成为业内权威，为集群提供运营指导。'
        },
        { title: '目的地招商', text: '举办线上线下招商活动，引入民宿品牌及配套设施。通过民宿学苑举办线下沙龙及集群招商活动，匹配目的地定位，链接多方资源，助力集群业态蓬勃发展。' },
        { title: '目的地生态', text: '整合旗下生态链产品，共同创建民宿集群。包括：云优选-民宿易耗品、小宿Design-民宿设计、Open Kitchen-餐饮培训、民宿保险-民宿保险业务等。' },
      ],
      currentNum: 0,
      currentText: '云掌柜积极响应国家「助力乡村振兴」的号召，正式推出「民宿集群-数字化管理服务方案」。为入驻集群的民宿提供CRS中央预订系统，解决业主多渠道营销管理的难题，提升民宿管理整体数字信息化水平，助力乡村文旅振兴。',
      createYear: new Date().getFullYear() - 2012,
      userNumber: "17"
    }
  },
  methods: {
    getUserNumber() {
      axios.get('https://api.yunzhanggui.net/api/v1/inn/get_hotel_total?version=9.4.6.0')
      .then((res) => {
        let number = Number(res.data.ret_data.total) / 10000
        this.userNumber = Math.floor(number)
        console.log(res.data.ret_data.total)
      })
      .catch(function (err) {
        return err
      })
    }
  },
  computed: {},
  props: {},
  watch: {},
  created () {
    this.getUserNumber()
  },
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("../assets/index.css");
@import url("../assets/media.css");
</style>
